import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "add-or-edit-event-locations"
    }}>{`Add or Edit Event Locations`}</h1>
    <p>{`Let's explore how to add additional event locations outside of your regular venues and how to edit the locations.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Admin level access permissions.</Alert>
    <h2 {...{
      "id": "add-location"
    }}>{`Add Location`}</h2>
    <p>{`If you need to add more event locations, an administrator will need to access the Advanced options.`}</p>
    <h2 {...{
      "id": "add-location-1"
    }}>{`Add Location`}</h2>
    <ol>
      <li parentName="ol">{`Select 'Advanced' from the Menu on the left-hand side`}</li>
      <li parentName="ol">{`Select 'Manage Event Locations'`}</li>
      <li parentName="ol">{`Select 'Create New Location'`}</li>
      <li parentName="ol">{`Add 'Name'- enter the name of the location`}</li>
      <li parentName="ol">{`Add 'Phone'- enter a phone number that people can use to find out more information about the event and to book over the phone`}</li>
      <li parentName="ol">{`Add Address- enter the address using Address Line 1, 2, Suburb, Postcode and State`}</li>
      <li parentName="ol">{`Add 'Website'- enter a website where members can find out more information about the venue or location`}</li>
      <li parentName="ol">{`Select 'Public Visibility' ' if this event location will be listed as a filtering option for the public when viewing locations`}</li>
      <li parentName="ol">{`Select 'Staff Visibility' ' if this event location will need to be selected for staff when adding events and filtering`}</li>
      <li parentName="ol">{`Click Create`}</li>
      <li parentName="ol">{`Click Close`}</li>
    </ol>
    <h2 {...{
      "id": "edit-location"
    }}>{`Edit Location`}</h2>
    <ol>
      <li parentName="ol">{`Select Advanced from the Menu on the left-hand side`}</li>
      <li parentName="ol">{`Select Manage Event Locations`}</li>
      <li parentName="ol">{`Find location under Current Locations`}</li>
      <li parentName="ol">{`Click on the field that needs to be edited`}</li>
      <li parentName="ol">{`Click Save`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/4ROIMLgvP2E" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>
    <Alert kind="info" mdxType="Alert">All changes to locations will be instant. Locations cannot be deleted but if you deselect 'Public Visibility' and 'Staff Visibility'  they will be removed from all views.</Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      